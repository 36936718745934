.navbar-container {
  /* height: 96px; */
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 100px;
  padding-right: 100px;
}
.navbar-container .ant-card-body::before,
.navbar-container .ant-card-body::after {
  display: none !important;
}

.width-33 {
  width: 33%;
}
.width-66 {
  width: 66%;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.d-flex {
  display: flex;
}
.justify-end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.x-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.y-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.l-15 {
  margin-left: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.b-15 {
  margin-bottom: 15px;
}
.outlined-primary {
  color: #eb7201;
  border: 1px solid #eb7201;
}
.contained-primary {
  background-color: #eb7201;
  color: #fff;
  border: 1px solid #eb7201;
}

.logo-name {
  font-weight: 600;
  color: #131b48 !important;
  font-size: 18px;
  margin-left: 12px;
}

.navbar-container.is-sticky {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #fff;
  position: fixed;
  box-shadow: 0px 2px 24px 0px rgb(0 0 0 / 6%);
  width: 100%;
  border-radius: 0px !important;
  z-index: 10;
}
@media only screen and (max-width: 700px) {
  .navbar-container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .wa-mobile {
    width: 48px !important;
    height: 48px;
    padding: 12px;
    border-radius: 8px;
    border: 1px;
    gap: 8px;
    background-color: #068f2c;
    margin-right: 19px;
  }
  .drawer-container {
    display: flex;
    flex-direction: column;
  }
}
