.banner-container {
  background-image: url("../../../../Assets/Images/banner.jpg"),
    linear-gradient(270deg, rgba(19, 27, 92, 0) 0%, rgba(19, 27, 92, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 98px);
  padding-left: 100px;
}

.banner-heading {
  font-size: 56px !important;
  font-weight: 300;
  max-width: 744px;
  line-height: 1.25 !important;
}

.banner-description {
  margin-top: 16px;
  max-width: 629px;
  font-size: 16px !important;
}

.quote-btn {
  background-color: #fff;
  border: none;
  font-size: 18px;
  color: #131b48;
  width: 225px;
  height: 64px;
  padding: 19px 39px;
  border-radius: 66px;
}

.quote-btn:hover {
  background-color: #e0e0e0 !important;
  border: none;
  color: #1b2666 !important;
}
