.dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#quote-form {
  width: 100%;
}
.get-quote-header {
  font-weight: 700;
  font-size: 24px !important;
  margin-left: 12px;
}

.quote-submit-btn {
  width: 100%;
  border-radius: 8px;
  background-color: #213e7a;
  height: 56px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.quote-submit-btn:hover {
  border: none;
  background-color: #182e5a !important;
  color: #efefef !important;
}

.ant-input {
  background-color: #fbfbfb;
  border: 1px solid #dbddf1;
}

.ant-input-outlined:hover {
  border: 1px solid #b7bae4;
}

.get-quote-header-container {
  margin-bottom: 41px;
}
