.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #213e7a;
}
.footer-container {
  display: flex;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(232, 236, 237, 0.16);
  max-width: 828px;
  .ant-typography {
    color: #fff;
  }
}
.footer-logo-name {
  color: #fff !important;
  font-weight: 600;
  font-size: 18px;
  margin-left: 12px;
}
.copyright-container {
  background-color: #213e7a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-logo {
  margin-bottom: 40px !important;
}
.footer-contact {
  display: flex;
  margin-top: 24px;
}
.footer-contact-img {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.footer-column-1 {
  max-width: 291px;
  padding-right: 40px;
  border-right: 1px solid rgba(232, 236, 237, 0.16);
}

.footer-links-main-container {
  display: flex;
  padding-left: 40px;
  // margin-left: 40px;
}

.footer-links-header {
  color: rgba(255, 255, 255, 0.5) !important;
  margin-bottom: 21.6px !important;
}
.footer-link {
  font-size: 14px !important;
}

.footer-link-holder {
  margin-left: 64px;
}

.copyright-container {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 828px;

  .footer-copyright {
    color: #fff !important;
  }
}
.quote-btn-footer {
  border-radius: 66px;
  width: 225px;
  height: 61px;
  font-weight: 500;
  font-size: 16px;
}
.quote-btn-footer:hover {
  background-color: #e0e0e0 !important;
  border: none;
  color: #1b2666 !important;
}
@media only screen and (max-width: 700px) {
  .footer-container,
  .footer-links-main-container {
    padding-top: 32px;
    flex-direction: column;
    padding-left: 0;
  }
  .footer-link-holder,
  .footer-link-holder-1 {
    margin-left: 0;
    margin-top: 10px;
  }

  .footer-contact {
    align-items: center;
  }
  .footer-contact-img {
    margin-right: 25px;
  }
  .copyright-container {
    width: 85%;
  }
  .footer-column-1 {
    border-right: none;
    border-bottom: 1px solid rgba(232, 236, 237, 0.16);
  }
  .footer-wrapper {
    width: 100%;
  }
}
