.our-services-container {
  background-image: url("../../../../Assets/Images/service_bg.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 135px;
}

.our-services-pre-heading-container {
  margin-top: 136px;
  display: flex;
  align-items: center;
}
.our-services-pre-heading {
  margin-left: 62px;
  margin-right: 62px;
  font-size: 24px;
}
.service-card-container {
  margin-top: 60px;
  max-width: 1196px;
}

.our-service-card {
  background-color: #f9fafc;
  border-radius: 0 !important;
  height: 385px;
}

.service-card-heading {
  margin-top: 32px;
  margin-bottom: 16px !important;
  font-weight: 700;
  font-size: 24px !important;
  color: #333e49 !important;
}

.service-card-content {
  font-weight: 400;
  font-size: 18px;
  color: #515864 !important;
  line-height: 28px !important;
}
