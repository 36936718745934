.commitment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../../Assets/Images/commitmen_bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}

.commitment-title {
  color: #fff !important;
  font-size: 68px;
  font-weight: 700;
}
.commitment-description {
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  max-width: 800px;
}
