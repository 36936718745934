.why-choose-container {
  background: url("../../../../Assets/Images/service_bg.png"), #fafbfe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.why-choose-cards-container {
  max-width: 1000px;
  margin-top: 60px;
}

.choose-card {
  height: 166px;
  border: 0.5px solid #acb8d076;
  .ant-card-body {
    display: flex;
  }
}
.choose-card-right {
  margin-left: 24px;
}
.chose-card-heading {
  margin-bottom: 8px !important;
  color: #212121 !important;
  font-size: 20px;
  font-weight: 600;
}
.chose-card-content {
  line-height: 20px;
  color: #717171 !important;
  font-size: 14px;
  font-weight: 400;
}
