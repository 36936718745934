* {
  font-family: "Outfit", sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-text {
  color: #eb7201 !important;
}

.white-text {
  color: #fff !important;
}
.black-text {
  color: rgba(20, 20, 20, 0.92) !important;
}
.grey-text {
  color: rgba(20, 20, 20, 0.44) !important;
}
.fullwidth {
  width: 100%;
}
.paragraph {
  font-size: 16px;
}
.cursor:hover {
  cursor: pointer;
}
.ant-card-cover {
  border-radius: 20px !important;
}
.ant-card {
  border-radius: 20px !important;
}
.ant-card-cover img {
  border-radius: 20px 20px 0 0 !important;
}
.ant-typography strong {
  font-weight: 700 !important;
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.reset-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.primary-heading {
  font-size: 60px !important;
  font-weight: 800 !important;
  color: #213e7a;
}

.active {
  .ant-typography {
    font-weight: 700 !important;
  }
}
a {
  text-decoration: none;
}
